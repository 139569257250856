<template>
  <div class="home">
    <!-- 头部区域 -->
    <div class="header-wrapper">
      <div class="header">
        <!-- 导航栏 -->
        <div class="header-top">
          <div class="header-top-left">
            <img v-if="lang === 'english'" src="../assets/math/logo-b2.png" />
            <img
              class="header-top-left-cn"
              v-else
              src="../assets/math/logo-b3.png"
            />
            <!-- <img v-if="lang === 'english'" src="../assets/math/logo-b-e.png" />
            <img v-else src="../assets/math/logo-b.png" /> -->
          </div>
          <div class="header-top-right">
            <div
              class="header-top-right-recruit text hover"
              @click="handleRecruit"
            >
              {{ tabbar[lang][0] }}
            </div>
            <div class="line"></div>
            <el-dropdown @command="changeLang" placement="bottom">
              <div class="el-dropdown-link text hover">
                <!-- {{ tabbar[lang][1] }} -->
                {{ lang === 'english' ? 'EN' : '中文' }}
                <img src="../assets/math/down-arrow.png" />
              </div>
              <el-dropdown-menu slot="dropdown" class="dropdown-menu">
                <el-dropdown-item command="english" class="dropdown-menu-item"
                  >EN</el-dropdown-item
                >
                <el-dropdown-item
                  command="chinese"
                  class="dropdown-menu-item"
                  >{{ lang === 'english' ? 'CN' : '中文' }}</el-dropdown-item
                >
              </el-dropdown-menu>
            </el-dropdown>
          </div>
        </div>
        <!-- 大标题 -->
        <div
          class="header-title"
          :class="{ 'title-chinese': lang === 'chinese' }"
        >
          {{ header[lang][0] }}
        </div>
        <!-- 小标题 -->
        <div class="header_wrap chinese_header_wrap">
          <div
            class="header-subtitle"
            :class="{ 'chinese-subtitle': lang === 'chinese' }"
            @click="toExperience"
          >
            <span>{{ header[lang][1] }}</span>
            <img src="../assets/math/icon_experience.png" alt="" />
          </div>
          <!-- <p class="report_btn" @click="toReport">{{ header[lang][2] }}</p> -->
        </div>
      </div>
    </div>
    <!-- 中间内容区域 -->
    <div class="middle-wrapper">
      <div class="middle" :class="{ 'middle-chinese': lang === 'chinese' }">
        <div
          class="middle-text"
          :class="{
            chinese: lang === 'chinese',
          }"
        >
          <!--          <span class="middle-text-span">{{ content[lang] }}</span>-->
          <!--          <span v-if="lang === 'english'" class="middle-text-span-sub">{{-->
          <!--            content["esubtitle"]-->
          <!--          }}</span>-->
          <MathContent :lang="lang"></MathContent>
          <div
            class="middle-text-img"
            :class="{
              'middle-text-circle': lang === 'chinese',
            }"
          >
            <img src="../assets/math/circle.png" />
          </div>
        </div>
      </div>
    </div>

    <!-- 中间招聘区域 -->
    <div class="recruit-wrapper">
      <div class="recruit" :class="{ 'recruit-ch': lang === 'chinese' }">
        <div
          class="recruit-title"
          :class="{ 'recruit-chtitle': lang === 'chinese' }"
        >
          {{ recruit[lang][0] }}
        </div>
        <div
          class="recruit-subtitle hover"
          :class="{ 'recruit-chsubtitle': lang === 'chinese' }"
          @click="handleRecruit"
        >
          <div class="recruit-subtitle-text">{{ recruit[lang][1] }}</div>
          <div class="recruit-subtitle-img">
            <img src="../assets/math/right-arrow.png" />
          </div>
        </div>
      </div>
    </div>

    <!-- 底部区域 -->
    <div class="footer-wrapper">
      <div class="footer">
        <div class="footer-top">
          <div class="footer-top-left">
            <div class="footer-top-left-text">{{ ftext[lang] }}</div>
            <div class="footer-top-left-bigtext">
              {{ fbigtext[lang] }}
            </div>
          </div>
          <div class="footer-top-right">
            <img src="../assets/math/shuxuejia.png" />
          </div>
        </div>
        <div class="footer-bottom">
          <div class="footer-bottom-left">
            <img v-if="lang === 'english'" src="../assets/math/logoxtal.png" />
            <img v-else src="../assets/math/logoxtal2.png" />
          </div>
          <div class="footer-bottom-right">
            <div v-if="isH5()">Copyright © 2023 MathGPT</div>
            <div v-else-if="lang === 'english'">Copyright © 2023 MathGPT</div>
            <div v-else>
              Copyright © 2023 MathGPT 北京世纪好未来教育科技有限公司版权所有 ｜
              爱与科技助力终身成长
            </div>
            <div v-if="lang === 'english'">
              To empower life-long growth with love and technology
            </div>
            <div v-else style="white-space: nowrap">
              <span style="cursor: pointer" @click="toBeian">
                京ICP备13017119号-13
              </span>
              ｜
              <img class="beian" src="../assets/math/beian.png" />
              <span style="cursor: pointer" @click="toBeian2">
                京公网安备11010802042225号
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- 吸顶栏 -->
    <div v-show="show" class="banner-wrapper">
      <div class="banner">
        <div class="banner-left">
          <img v-if="lang === 'english'" src="../assets/math/logo-w2.png" />
          <img class="banner-left-cn" v-else src="../assets/math/logo-w3.png" />
          <!-- <img v-if="lang === 'english'" src="../assets/math/logo-w-e.png" />
          <img v-else src="../assets/math/logo-w.png" />
     -->
        </div>
        <div class="banner-right">
          <div class="banner-right-recruit text hover" @click="handleRecruit">
            {{ tabbar[lang][0] }}
          </div>
          <div class="line"></div>
          <el-dropdown @command="changeLang" placement="bottom">
            <span class="el-dropdown-link text hover">
              <!-- {{ tabbar[lang][1] }} -->
              {{ lang === 'english' ? 'EN' : '中文' }}
              <img src="../assets/math/down-arrow-banner.png" />
            </span>
            <el-dropdown-menu slot="dropdown" class="dropdown-menu">
              <el-dropdown-item command="english" class="dropdown-menu-item"
                >EN</el-dropdown-item
              >
              <el-dropdown-item command="chinese" class="dropdown-menu-item">{{
                lang === 'english' ? 'CN' : '中文'
              }}</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import MathContent from '@/pages/MathContent.vue'
export default {
  components: {
    MathContent,
  },
  data() {
    return {
      lang: 'english',
      show: false,
      rendered: false,
      tabbar: {
        english: ['Careers', 'EN'],
        chinese: ['人才招聘', '中文'],
      },
      language: {
        en: 'english',
        zh: 'chinese',
      },
      header: {
        english: [
          'MathGPT(学而思九章大模型) is an LLM developed by TAL Education Group, which focuses on math related problem solving and lecturing for worldwide research institutions and math enthusiasts.',
          'Try on web',
          'MathGPT Technical Report',
        ],
        chinese: [
          '学而思九章大模型（MathGPT）是好未来自主研发的，面向全球数学爱好者和科研机构，以解题和讲题算法为核心的大模型。',
          '开始体验',
          'MathGPT Technical Report',
        ],
      },
      content: {
        english:
          'MathGPT aims at understanding, generating, reasoning, problem-solving and human-interactions in math problems, which is a vital frontier to conquer on the path to exploring artificial general intelligence. ',
        esubtitle:
          'We plan to release a challenging and representative math problem solving benchmark datasets in August 2023, which will be publicly availabe for worldwide AI researchers, practitioners and math enthusiasts.',
        chinese:
          'MathGPT致力于数学任务的理解、生成、推理、解答和互动，是探索通用人工智能路上必须攻克的高地。我们预计在2023年8月份，公开一个具有代表性和挑战性的数学任务评测集，供全球人工智能专家、数学爱好者体验和测评。',
      },
      recruit: {
        english: [
          'We have recruited a group of excellent mathematics teachers, AI scientists and engineers working on MathGPT(学而思九章大模型). We are globally seeking ambitious, resilient, and talented individuals, including but not limited to math geeks, research scientists, infra experts, and data engineers. We look forward to your joining us.',
          'Join us',
        ],
        chinese: [
          '学而思九章大模型（MathGPT）已经凝聚一批优秀且年轻的数学老师、算法科学家和工程师，我们正在全球范围内寻找志向远大、敢于攻坚克难、才华横溢的各类人才，包括且不限于数学极客、算法科学家、infra专家、数据工程师等。期待您的加入。',
          '加入我们',
        ],
      },
      footer: {
        english: 'To empower life-long growth with love and technology',
        chinese: '爱与科技助力终身成长',
      },
      ftext: {
        english: 'David Hilbert, Famous mathematician',
        chinese: '戴维·希尔伯特，著名数学家',
      },
      fbigtext: {
        english: 'Wir müssen wissen, Wir werden wissen.',
        chinese: '我们必须知道，我们必将知道。',
      },
    }
  },
  // created() {
  //   document.title = "九章大模型(MathGPT)";
  // },
  mounted() {
    this.lang = this.getUserLang()
    window.addEventListener('scroll', () => {
      let scrollTop =
        document.documentElement.scrollTop || document.body.scrollTop
      if (scrollTop > 0) this.show = true
      else this.show = false
    })
  },
  methods: {
    changeLang(lang) {
      this.lang = lang
    },
    handleRecruit() {
      this.$router.push({ name: 'Recruit', query: { lang: this.lang } })
      window.scrollTo({
        top: 0,
      })
    },
    isH5() {
      const reg =
        /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i
      if (reg.test(window.navigator.userAgent)) {
        return true
      } else {
        return false
      }
    },
    getUserLang() {
      const query = this.$route.query
      const syslang = (
        navigator.browserLanguage ||
        navigator.language ||
        navigator.userLanguage
      ).substring(0, 2)
      const lang = query.lang || this.language[syslang] || 'english'
      return lang
    },
    toReport() {
      window.open(
        'https://doc-playground.xes1v1.cn/MathGPT_Technical_Report.pdf'
      )
    },
    toExperience() {
      window.open(
        `https://playground.xes1v1.cn?language=${
          this.lang === 'english' ? 'en' : 'cn'
        }`
      )
    },
    toBeian() {
      window.open('https://beian.miit.gov.cn/#/Integrated/index')
    },
    toBeian2() {
      window.open(
        'http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=11010802042225'
      )
    },
  },
}
</script>

<style lang="less" scoped>
.home {
  width: 100vw;
  // overflow-x: hidden;
  .header-wrapper {
    width: 100vw;
    background: linear-gradient(322.86deg, #9bff1f -35.65%, #77efff 66.14%);
    background-size: 150% 150%;
    animation: Gradient 6s ease-in infinite;
    .header {
      width: 1920px;
      height: 1080px;
      margin: 0 auto;
      padding: 30px 64px 0;
      box-sizing: border-box;
      &-top {
        display: flex;
        justify-content: space-between;
        &-left {
          display: flex;
          align-items: center;
          cursor: pointer;
          img {
            width: 250px;
            //height: 60px;
            object-fit: cover;
            vertical-align: top;
          }
          &-cn {
            width: 500px !important;
          }
        }
        &-right {
          display: flex;
          align-items: center;
          .line {
            width: 2px;
            height: 25px;
            background: #000;
            margin: 0 74px;
          }
          .text {
            font-family: 'PingFang SC';
            font-weight: 400;
            font-size: 25px;
            color: #000;
          }
        }
      }
      &-title {
        margin: 169px 0 0 46px;
        padding: 0 284px 0 0;
        font-family: 'PingFang SC';
        font-weight: 400;
        font-size: 70px;
        line-height: 90px;
      }
      .title-chinese {
        margin: 199px 0 0 46px;
        line-height: 110px;
      }
      &-subtitle {
        font-weight: 400;
        font-size: 35px;
        width: 400px;
        border-radius: 30px;
        border: 2px solid #000;
        text-align: center;
        line-height: 95px;
        font-family: PingFang SC;
        cursor: pointer;
        img {
          width: 28px;
          vertical-align: middle;
          margin-left: 19px;
        }
        span {
          vertical-align: middle;
        }
      }
      .chinese-subtitle {
        width: 300px;
      }
    }
    .header_wrap {
      display: flex;
      align-items: center;
      margin: 65px 0 0 46px;
      .report_btn {
        font-weight: 400;
        font-size: 35px;
        font-family: PingFang SC;
        cursor: pointer;
        margin-left: 62px;
        position: relative;
        &::after {
          content: '';
          position: absolute;
          left: 0;
          bottom: 0;
          width: 100%;
          height: 2px;
          background-color: black; /* 设置下划线颜色 */
          margin-bottom: 3px; /* 设置下划线与文本底部的间距 */
        }
      }
    }
    .chinese_header_wrap {
      margin: 105px 0 0 46px;
    }
  }
  .middle-wrapper {
    width: 100vw;
    background: #20261c;
    .middle {
      width: 1920px;
      //height: 1700px;
      margin: 0 auto;
      padding: 100px 0 0;
      box-sizing: border-box;
      background-image: url('../assets/math/bg3.png');
      background-size: 100% 100%;
      background-repeat: no-repeat;
      &-text {
        width: 1500px;
        margin: 0 0 0 110px;
        font-family: 'PingFang SC';
        font-weight: 400;
        font-size: 70px;
        line-height: 90px;
        color: #fff;
        position: relative;
        &-span {
          position: relative;
          z-index: 999;
          &-sub {
            display: inline-block;
            margin: 60px 0 0 0;
            font-size: 35px;
            line-height: 55px;
          }
        }
        &-img {
          position: absolute;
          right: -20px;
          top: -30px;
          width: 273px;
          z-index: 100;
          img {
            width: 100%;
            object-fit: cover;
            vertical-align: top;
          }
        }
        &-circle {
          right: -70px;
        }
      }
    }
    .middle-chinese {
      padding: 150px 0 0;
    }
  }

  .recruit-wrapper {
    width: 100vw;
    background: linear-gradient(124.5deg, #d9fe9a -36.5%, #60fdc6 54.13%);
    .recruit {
      width: 1920px;
      height: 1080px;
      margin: 0 auto;
      padding: 112px 160px 0 110px;
      box-sizing: border-box;
      background: linear-gradient(124.5deg, #d9fe9a -36.5%, #60fdc6 54.13%);
      position: relative;
      &-title {
        padding: 0 256px 0 0;
        font-family: 'PingFang SC';
        font-weight: 400;
        font-size: 70px;
        line-height: 90px;
      }
      &-subtitle {
        position: absolute;
        right: 160px;
        bottom: 110px;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        &-text {
          margin: 0 26px 0 0;
          height: 40px;
          font-family: 'PingFang SC';
          font-weight: 400;
          font-size: 35px;
          line-height: 40px;
        }
        &-img {
          width: 31px;
          height: 26px;
          display: flex;
          align-items: center;
          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            vertical-align: top;
          }
        }
      }
    }
    .recruit-ch {
      padding: 192px 80px 0 110px;
      .recruit-chtitle {
        line-height: 110px;
      }
      .recruit-chsubtitle {
        bottom: 150px;
      }
    }
  }

  .footer-wrapper {
    width: 100vw;
    background: #000;
    .footer {
      width: 1920px;
      margin: 0 auto;
      padding: 74px 66px;
      box-sizing: border-box;
      background: #000;
      &-top {
        padding: 0 48px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        &-left {
          &-text {
            font-family: 'PingFang SC';
            font-weight: 400;
            font-size: 35px;
            line-height: 1;
            color: #fff;
          }
          &-bigtext {
            margin: 26px 0 0 0;
            font-family: 'PingFang SC';
            font-weight: 500;
            font-size: 70px;
            line-height: 1;
            color: #fff;
          }
        }
        &-right {
          width: 170px;
          img {
            width: 100%;
            object-fit: cover;
            vertical-align: top;
          }
        }
      }
      &-bottom {
        margin: 174px 0 0 0;
        display: flex;
        justify-content: space-between;
        align-items: center;
        &-left {
          width: 516px;
          img {
            width: 99%;
            object-fit: cover;
            vertical-align: top;
          }
        }
        &-right {
          display: flex;
          flex-direction: column;
          align-items: flex-end;
          color: #fff;
          font-family: 'PingFang SC';
          font-weight: 400;
          font-size: 20px;
          line-height: 40px;
          .beian {
            width: 20px;
            img {
              width: 100%;
              object-fit: cover;
              vertical-align: top;
            }
          }
        }
      }
    }
  }

  .banner-wrapper {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    background: #000;
    z-index: 1000;
    .banner {
      width: 1920px;
      height: 120px;
      margin: 0 auto;
      padding: 30px 64px;
      box-sizing: border-box;
      background: #000;
      display: flex;
      justify-content: space-between;
      align-items: center;
      &-left {
        display: flex;
        align-items: center;
        img {
          width: 250px;
          //height: 60px;
          object-fit: cover;
          vertical-align: top;
        }
        &-cn {
          width: 500px !important;
        }
      }
      &-right {
        display: flex;
        align-items: center;
        .line {
          width: 2px;
          height: 30px;
          background: #fff;
          margin: 0 74px;
        }
        .text {
          font-family: 'PingFang SC';
          font-weight: 400;
          font-size: 25px;
          color: #fff;
        }
      }
    }
  }
}
// 中文行高
.chinese {
  line-height: 110px !important;
}
// 鼠标悬浮效果
.hover:hover {
  opacity: 0.4;
  cursor: pointer;
}
// 下拉框
.el-dropdown-link {
  display: flex;
  align-items: center;
  img {
    width: 15px;
    height: 9px;
    margin: 0 0 0 9px;
    object-fit: cover;
    vertical-align: top;
  }
}
// 下拉菜单
.dropdown-menu {
  padding: 10px 0 !important;
  border: 1px solid #000;
  border-radius: 10px;
  background: #000;
  &-item {
    padding: 10px 30px !important;
    font-family: 'PingFang SC' !important;
    font-weight: 400 !important;
    font-size: 25px !important;
    color: #fff;
    &:hover {
      color: #60fdc7 !important;
      background-color: #000 !important;
    }
  }
  /deep/ .popper__arrow {
    display: none !important;
  }
}
// 背景渐变色动画
@keyframes Gradient {
  0% {
    background-position: 100% 50%;
  }
  75% {
    background-position: 0% 50%;
  }
  100% {
    background-position: 100% 50%;
  }
}
@media screen and (max-width: 720px) {
  .header-wrapper {
    .header {
      &-subtitle {
        line-height: normal !important;
      }
    }
  }
  .footer-wrapper {
    .footer {
      &-bottom {
        &-right {
          line-height: normal !important;
        }
      }
    }
  }
}
</style>
